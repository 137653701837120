import React from 'react';
import { Button, HeadingBoundary, Headline, ManagedInput, ManagedSelect, Option } from '@panda/ui';
import { Form, Formik, useFormikContext } from 'formik';
import classnames from 'classnames';
import { ManagedForm } from '@web-apps/forms';
import classes from './NQNupsi.scss';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setShowModal } from '../../redux/slices/nqnupsi';
import { SupportedLocales } from '../../types';
import GermanFlag from './images/de_DE.png';
import UKFlag from './images/en_GB.png';
import { showModal } from '../../redux/slices/nqnupsi/selectors';
import { useTranslations } from '../../hooks/translations';
import {
	getCountry,
	getDomain,
	getProduct,
	getSignupData,
} from '../../redux/slices/signup/selectors';
import { setCountry, setDomain, setProduct } from '../../redux/slices/signup';
import { fetchTranslations } from '../../redux/slices/translations';

const NQ_ONLY = 'nq_only';
const LDAP_NAME = 'ldap_name';

export const isNqDomain = () => !!sessionStorage.getItem(NQ_ONLY);

export const NqModal = () => {
	const ldapName = localStorage.getItem(LDAP_NAME) ? localStorage.getItem(LDAP_NAME) : 'you';

	const dispatch = useAppDispatch();
	const { translate, language } = useTranslations();

	const shouldShowModal = useAppSelector(showModal);
	const country = useAppSelector(getCountry);
	const domain = useAppSelector(getDomain);
	const product = useAppSelector(getProduct);

	const onSubmit = (values: { ldapName: string; language: string; signup: string }) => {
		localStorage.setItem(LDAP_NAME, values.ldapName);
		dispatch(fetchTranslations(values.language as SupportedLocales));
		dispatch(setShowModal(false));

		switch (values.signup) {
			case 'DE_INTERNATIONAL':
				dispatch(setCountry('NL'));
				dispatch(setProduct('team'));
				dispatch(setDomain('sipgate.de'));
				break;
			case 'DE_TRUNKING':
				dispatch(setCountry('DE'));
				dispatch(setProduct('trunking'));
				dispatch(setDomain('sipgate.de'));
				break;
			case 'DE_TRUNKING_INTERNATIONAL':
				dispatch(setCountry('NL'));
				dispatch(setProduct('trunking'));
				dispatch(setDomain('sipgate.de'));
				break;
			case 'DE_AFFILIATE':
				dispatch(setCountry('DE'));
				dispatch(setProduct('affiliate'));
				dispatch(setDomain('sipgate.de'));
				break;
			case 'DE':
				dispatch(setCountry('DE'));
				dispatch(setProduct('team'));
				dispatch(setDomain('sipgate.de'));
				break;
			case 'DE_NEOPBX':
				dispatch(setCountry('DE'));
				dispatch(setProduct('team_neopbx'));
				dispatch(setDomain('sipgate.de'));
				break;
			case 'UK_INTERNATIONAL':
				dispatch(setCountry('NL'));
				dispatch(setProduct('team'));
				dispatch(setDomain('sipgate.co.uk'));
				break;
			case 'UK_TRUNKING':
				dispatch(setCountry('GB'));
				dispatch(setProduct('trunking'));
				dispatch(setDomain('sipgate.co.uk'));
				break;
			case 'UK_TRUNKING_INTERNATIONAL':
				dispatch(setCountry('NL'));
				dispatch(setProduct('trunking'));
				dispatch(setDomain('sipgate.co.uk'));
				break;
			case 'UK_NEOPBX':
				dispatch(setCountry('GB'));
				dispatch(setProduct('team_neopbx'));
				dispatch(setDomain('sipgate.co.uk'));
				break;
			case 'GB':
				dispatch(setCountry('GB'));
				dispatch(setProduct('team'));
				dispatch(setDomain('sipgate.co.uk'));
				break;
		}
	};

	const getSignupDefault = () => {
		if (domain === 'sipgate.de') {
			if (country === 'DE') {
				if (product === 'trunking') {
					return 'DE_TRUNKING';
				}

				if (product === 'affiliate') {
					return 'DE_AFFILIATE';
				}
				return 'DE';
			}

			if (product === 'trunking') {
				return 'DE_TRUNKING_INTERNATIONAL';
			}
			return 'DE_INTERNATIONAL';
		}

		if (domain === 'sipgate.co.uk') {
			if (country === 'GB') {
				if (product === 'trunking') {
					return 'UK_TRUNKING';
				}
				return 'GB';
			}

			if (product === 'trunking') {
				return 'UK_TRUNKING_INTERNATIONAL';
			}
			return 'UK_INTERNATIONAL';
		}

		return 'DE';
	};

	if (!shouldShowModal) {
		return null;
	}

	return (
		<div className={classes.modal}>
			<div className={classnames(classes.body)}>
				<div className={classes.close}>
					<Button
						icon="close"
						iconOnly
						onClick={() => dispatch(setShowModal(false))}
						size="medium"
						variant="quiet"
					/>
				</div>
				<HeadingBoundary>
					<Headline>{translate('SIGNUP_NQ_NUPSI_HEADLINE')}</Headline>
				</HeadingBoundary>
				<div className={classes.content}>
					<Formik
						initialValues={{
							ldapName: ldapName || '',
							language: language.valueOf(),
							signup: getSignupDefault(),
						}}
						onSubmit={onSubmit}
					>
						{formik => (
							<Form>
								<div className={classnames(classes.row, classes.width2)}>
									<ManagedInput
										managedField={ManagedForm.buildField(formik, 'ldapName')}
										label={translate('SIGNUP_NQ_NUPSI_EMAIL_PREFIX_LABEL')}
										placeholder={translate('SIGNUP_NQ_NUPSI_EMAIL_PREFIX_PLACEHOLDER')}
									/>
									<ManagedSelect
										managedField={ManagedForm.buildField(formik, 'language')}
										title={translate('SIGNUP_NQ_NUPSI_LANGUAGE_LABEL')}
									>
										<Option value="de_DE">
											<span className={classes.flag}>
												<img src={GermanFlag} alt="Flag Germany" />
												{translate('SIGNUP_NQ_NUPSI_LANGUAGE_GERMAN')}
											</span>
										</Option>
										<Option value="en_GB">
											<span className={classes.flag}>
												<img src={UKFlag} alt="Flag UK" />
												{translate('SIGNUP_NQ_NUPSI_LANGUAGE_ENGLISH')}
											</span>
										</Option>
									</ManagedSelect>
									<ManagedSelect
										managedField={ManagedForm.buildField(formik, 'signup')}
										title={translate('SIGNUP_NQ_NUPSI_SIGNUP_LABEL')}
									>
										<Option value="DE">
											<span className={classes.flag}>
												<img src={GermanFlag} alt="Flag Germany" />
												{translate('SIGNUP_NQ_NUPSI_SIGNUP_DE')}
											</span>
										</Option>
										<Option value="DE_NEOPBX">
											<span className={classes.flag}>
												<img src={GermanFlag} alt="Flag Germany" />
												{translate('SIGNUP_NQ_NUPSI_SIGNUP_DE_NEOPBX')}
											</span>
										</Option>
										<Option value="DE_INTERNATIONAL">
											<span className={classes.flag}>
												<img src={GermanFlag} alt="Flag Germany" />
												{translate('SIGNUP_NQ_NUPSI_SIGNUP_INTERNATIONAL')}
											</span>
										</Option>
										<Option value="DE_TRUNKING">
											<span className={classes.flag}>
												<img src={GermanFlag} alt="Flag Germany" />
												{translate('SIGNUP_NQ_NUPSI_SIGNUP_DE_TRUNKING')}
											</span>
										</Option>
										<Option value="DE_TRUNKING_INTERNATIONAL">
											<span className={classes.flag}>
												<img src={GermanFlag} alt="Flag Germany" />
												{translate('SIGNUP_NQ_NUPSI_SIGNUP_DE_TRUNKING_INTERNATIONAL')}
											</span>
										</Option>
										<Option value="DE_AFFILIATE">
											<span className={classes.flag}>
												<img src={GermanFlag} alt="Flag Germany" />
												{translate('SIGNUP_NQ_NUPSI_SIGNUP_DE_AFFILIATE')}
											</span>
										</Option>
										<Option value="GB">
											<span className={classes.flag}>
												<img src={UKFlag} alt="Flag UK" />
												{translate('SIGNUP_NQ_NUPSI_SIGNUP_UK')}
											</span>
										</Option>
										<Option value="UK_NEOPBX">
											<span className={classes.flag}>
												<img src={UKFlag} alt="Flag UK" />
												{translate('SIGNUP_NQ_NUPSI_SIGNUP_UK_NEOPBX')}
											</span>
										</Option>
										<Option value="UK_TRUNKING">
											<span className={classes.flag}>
												<img src={UKFlag} alt="Flag UK" />
												{translate('SIGNUP_NQ_NUPSI_SIGNUP_UK_TRUNKING')}
											</span>
										</Option>
										<Option value="UK_INTERNATIONAL">
											<span className={classes.flag}>
												<img src={UKFlag} alt="Flag UK" />
												{translate('SIGNUP_NQ_NUPSI_SIGNUP_INTERNATIONAL')}
											</span>
										</Option>
										<Option value="UK_TRUNKING_INTERNATIONAL">
											<span className={classes.flag}>
												<img src={UKFlag} alt="Flag UK" />
												{translate('SIGNUP_NQ_NUPSI_SIGNUP_UK_TRUNKING_INTERNATIONAL')}
											</span>
										</Option>
									</ManagedSelect>
								</div>
								<div className={classes.submit}>
									<Button
										type="submit"
										action="confirm"
										size="medium"
										variant="normal"
										width="max-on-touch-device"
									>
										{translate('SIGNUP_NQ_NUPSI_SUBMIT')}
									</Button>
									<Button
										onClick={() => dispatch(setShowModal(false))}
										size="medium"
										variant="quiet"
									>
										{translate('SIGNUP_NQ_NUPSI_CANCEL')}
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

const NqNupsi = () => {
	const ldapName = localStorage.getItem(LDAP_NAME) ? localStorage.getItem(LDAP_NAME) : 'you';

	const dispatch = useAppDispatch();
	const shouldShowModal = useAppSelector(showModal);

	const { translate } = useTranslations();

	const {
		values: { country },
		setValues,
	} = useFormikContext<{ country: string }>();

	const randomString = (length: number) => {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
		const charactersLength = characters.length;
		let counter = 0;
		while (counter < length) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
			counter += 1;
		}
		return result;
	};

	const fillForm = (email: string | undefined) => {
		const random = randomString(8);

		if (country === 'DE') {
			const signupData = {
				email: email || `${ldapName}+${random}@sipgate.de`,
				password: randomString(12),
				firstname: 'Peterle',
				lastname: `Drobusch-${random}`,
				phoneNumber: '+49123456789',
				company: 'Sample GmbH',
				country: 'DE',
				zip: '47055',
				city: 'Duisburg',
				street: 'Zum Lith',
				number: '1',
				testaccount: true,
				emergencyCalls: true,
			};
			setValues(signupData);
			return;
		}
		if (country === 'GB') {
			const signupData = {
				email: email || `${ldapName}+${random}@sipgate.co.uk`,
				password: randomString(12),
				firstname: 'Peterle',
				lastname: `Drobusch-${random}`,
				phoneNumber: '+49123456789',
				company: 'Sample Ltd.',
				country: 'GB',
				zip: 'LS1 2RA',
				address1: '17 Westgate',
				address2: '',
				selectedAddress: '',
				city: 'Leeds',
				street: '17 Westgate',
				testaccount: true,
				emergencyCalls: true,
			};
			setValues(signupData);
			return;
		}

		const signupData = {
			email: `${ldapName}+${random}@sipgate.de`,
			password: randomString(12),
			firstname: 'Peterle',
			lastname: `Drobusch-${random}`,
			phoneNumber: '+33123456789',
			company: 'Sample GmbH',
			address1: 'Singel 250-sous ',
			address2: 'Basement',
			zip: '1016',
			city: 'Amsterdam',
			country: 'NL',
			testaccount: true,
			emergencyCalls: true,
		};

		setValues(signupData);
	};

	const { email } = useAppSelector(getSignupData);
	if (!isNqDomain()) {
		return null;
	}

	return (
		<span className={classes.buttons}>
			<Button action="trigger" onClick={() => fillForm(email)} size="medium">
				{translate('SIGNUP_NQ_NUPSI_BUTTON_LABEL')}
			</Button>
			<Button
				icon="edit"
				iconOnly
				onClick={() => dispatch(setShowModal(!shouldShowModal))}
				size="medium"
				variant="quiet"
			/>
		</span>
	);
};

export default NqNupsi;
