import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import api from '../../api';
import { useAppSelector } from '../../hooks/redux';
import useSignupData from '../../hooks/SignupData';
import { getIdentifier } from '../../redux/slices/signup/selectors';

const getFieldValue = <T>(key: string, values: unknown): T => {
	const data = Object.entries(values as ArrayLike<unknown>).find(([lKey]) => lKey === key);
	return data?.[1] as T;
};

const storeData = (country: string, values: unknown, identifier: string) => {
	let specificAddressData:
		| Partial<{ street: string; houseNumber: string }>
		| Partial<{ address1: string; address2: string }>;

	if (country === 'DE') {
		specificAddressData = {
			street: getFieldValue<string>('street', values) || undefined,
			houseNumber: getFieldValue<string>('number', values) || undefined,
		};
	} else {
		specificAddressData = {
			address1: getFieldValue<string>('address1', values) || undefined,
			address2: getFieldValue<string>('address2', values) || undefined,
		};
	}

	const data = {
		firstname: getFieldValue<string>('firstname', values) || undefined,
		lastname: getFieldValue<string>('lastname', values) || undefined,
		phoneNumber: getFieldValue<string>('phoneNumber', values) || undefined,
		company: getFieldValue<string>('company', values) || undefined,
		subscribedToNewsletter: true,
		address: {
			type: country === 'DE' ? 'GERMAN' : 'INTERNATIONAL',
			zipCode: getFieldValue<string>('zip', values) || undefined,
			city: getFieldValue<string>('city', values) || undefined,
			country: country || undefined,
			...specificAddressData,
		},
	};

	api.savePartialSignup(identifier, data);
};

const useFormikPartialStorage = () => {
	const { identifier } = useParams<{ identifier: string }>();
	const formik = useFormikContext();
	const country = useAppSelector(state => state.signup.country);
	const { signupData } = useSignupData(identifier);

	const debouncedStoreData = useRef(debounce(storeData, 500));
	const isCreating = useAppSelector(state => state.signup.creatingPending);
	const isCreated = useAppSelector(state => state.signup.createdPending);

	useEffect(() => {
		if (isCreating || isCreated) {
			if (debouncedStoreData.current) {
				debouncedStoreData.current.cancel();
			}
			return;
		}
		if (!formik.values || !country || !identifier) {
			return;
		}

		if (debouncedStoreData.current) {
			debouncedStoreData.current.cancel();
		}
		if (formik.dirty) {
			debouncedStoreData.current(country, formik.values, identifier);
		}
	}, [formik.values, country, identifier, signupData, isCreating, isCreated, formik]);

	return null;
};

const useFormikSignupData = () => {
	const identifier = useAppSelector(getIdentifier);
	const { signupData } = useSignupData(identifier);
	const formik = useFormikContext();

	useEffect(() => {
		for (const [key, value] of Object.entries(signupData)) {
			if (formik.getFieldMeta(key).value === '' && formik.getFieldMeta(key).touched === false) {
				if (value !== undefined) {
					formik.setFieldTouched(key, true);
				}
			}
		}
	}, [formik, signupData]);

	return null;
};

export { useFormikPartialStorage, useFormikSignupData };
