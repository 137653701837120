import { err, ok, Result } from 'neverthrow';
import { SipgateApi } from '../SipgateApi';
import { Translations } from '../types/translations';
import { ApiError, ApiErrors } from './errors/ApiError';
import {
	isGermanSignupData,
	isInternationalSignupData,
	PartialSignupDataWithEmail,
	SignupApiRequest,
	SignupData,
	SignupCreatedResponse,
	SignupResponse,
	SavePartialSignupRequest,
} from '../../types';
import {
	isSignupApiError,
	SIGNUP_NOT_FOUND_ERROR,
	SignupApiError,
	SignupApiErrors,
} from './errors/SignupApiError';
import {
	ACTIVATION_ALREADY_FINISHED,
	ACTIVATION_NOT_FOUND,
	ActivationApiError,
	ActivationApiErrors,
	isActivationError,
} from './errors/ActivationApiError';
import { getCookie } from '../../utils/cookies';
import { getFingerprint } from '../../utils/fingerprint';
import { UkAddressResponse } from '../types/UkAddressResponse';
import { AddressApiError, AddressApiErrors } from './errors/AddressApiError';
import HttpClient from './HttpClient';
import { TacIDResponse } from '../types/TacIDResponse';
import { isUaasApiError, uaasErrorToAddressError } from './errors/UaasApiError';

const DEFAULT_HEADERS = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
	'X-Sipgate-Client': 'signup.sipgate.com',
	'X-Sipgate-Version': '1.0.0',
};

export class RestApiClient implements SipgateApi {
	private readonly baseUrl: string;

	public constructor(baseUrl: string) {
		this.baseUrl = baseUrl;
	}

	public async getTranslations(locale: string): Promise<Result<Translations, ApiError>> {
		const response = await HttpClient.fetch(`${this.baseUrl}/v2/translations/${locale}`, {
			method: 'get',
			headers: {
				...DEFAULT_HEADERS,
			},
		});

		switch (response.status) {
			case 200: {
				const jsonBody = await response.json();
				return ok(jsonBody as Translations);
			}
			default:
				return err(ApiErrors.GeneralApiError('Could not get translations'));
		}
	}

	public async getCities(zip: string): Promise<Result<Array<string>, AddressApiError>> {
		const url = new URL(`${this.baseUrl}/v3/address-lookup/de/cities`);
		url.searchParams.append('zip', zip);

		const response = await HttpClient.fetch(url.toString(), {
			method: 'GET',
			headers: {
				Accept: 'application/json',
			},
		});

		const jsonBody = await response.json();

		switch (response.status) {
			case 200:
				return ok(jsonBody.items as string[]);
			default: {
				if (isUaasApiError(jsonBody)) {
					return err(uaasErrorToAddressError(jsonBody));
				}

				return err(AddressApiErrors.GeneralError());
			}
		}
	}

	public async getStreets(
		zip: string,
		city: string
	): Promise<Result<Array<string>, AddressApiError>> {
		const url = new URL(`${this.baseUrl}/v3/address-lookup/de/streets`);
		url.searchParams.append('city', city);
		url.searchParams.append('zip', zip);

		const response = await HttpClient.fetch(url.toString(), {
			method: 'GET',
			headers: {
				Accept: 'application/json',
			},
		});
		const jsonBody = await response.json();

		switch (response.status) {
			case 200:
				return ok(jsonBody.items as string[]);
			default: {
				if (isUaasApiError(jsonBody)) {
					return err(uaasErrorToAddressError(jsonBody));
				}

				return err(AddressApiErrors.GeneralError());
			}
		}
	}

	public async createPartialSignup(
		data: PartialSignupDataWithEmail
	): Promise<Result<SignupCreatedResponse, SignupApiError>> {
		const response = await HttpClient.fetch(`${this.baseUrl}/v3/signup`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});
		const jsonBody = await response.json();

		switch (response.status) {
			case 201:
				return ok(jsonBody as SignupCreatedResponse);
			case 409:
				return err(SignupApiErrors.EmailAlreadyExistsError(jsonBody.message));
			default:
				return err(SignupApiErrors.GeneralError('Could not create partial signup'));
		}
	}

	public getUtmParams = (): string => {
		/* eslint-disable camelcase */
		/* eslint-disable no-underscore-dangle */

		if (!window || window.__tpt === undefined) {
			return '';
		}

		const utm_campaign_last = window.__tpt.cha.cam || '';
		const utm_id_last = window.__tpt.cha.camid || '';
		const utm_creative_format_last = window.__tpt.cha.ccf || '';
		const utm_marketing_tactic_last = window.__tpt.cha.cmt || '';
		const utm_content_last = window.__tpt.cha.con || '';
		const utm_source_platform_last = window.__tpt.cha.csp || '';
		const utm_medium_last = window.__tpt.cha.med || '';
		const utm_source_last = window.__tpt.cha.sou || '';
		const utm_term_last = window.__tpt.cha.ter || '';
		const utm_click_id_type_last = window.__tpt.cha.clp || '';

		const utmParams = {
			utm_campaign_last,
			utm_id_last,
			utm_creative_format_last,
			utm_marketing_tactic_last,
			utm_content_last,
			utm_source_platform_last,
			utm_medium_last,
			utm_source_last,
			utm_term_last,
			utm_click_id_type_last,
		};

		/* eslint-enable camelcase */
		/* eslint-enable no-underscore-dangle  */

		return JSON.stringify(utmParams);
	};

	public async createSignup(
		data: SignupData,
		identifier?: string
	): Promise<Result<SignupCreatedResponse, SignupApiError>> {
		const signupData: Partial<SignupApiRequest> = {
			firstname: data.firstname,
			lastname: data.lastname,
			email: data.email,
			tacIds: data.tacIds,
			subscribedToNewsletter: false,
			marketingId: 1,
			command: data.product,
			testAccount: data.testaccount,
			password: data.password,
			partnerId: getCookie('partnerId') || '',
			domain: data.domain,
			company: data.company,
			phoneNumber: data.phoneNumber,
			ls2hs: localStorage.getItem('ls2hs') || '',
			utmParams: this.getUtmParams(),
		};

		if (isGermanSignupData(data)) {
			signupData.address = {
				type: 'GERMAN',
				country: data.country.toUpperCase(),
				city: data.city,
				zipCode: data.zip,
				houseNumber: data.number,
				street: data.street,
			};
		} else if (isInternationalSignupData(data)) {
			signupData.address = {
				type: 'INTERNATIONAL',
				country: (data.country as string).toUpperCase(),
				address1: data.internationalAddress1,
				address2: data.internationalAddress2,
				city: data.city,
				zipCode: data.zip,
			};
		}
		const requestUrl = identifier
			? `${this.baseUrl}/v3/signup/${identifier}/pending`
			: `${this.baseUrl}/v3/signup/pending`;

		const response = await HttpClient.fetch(requestUrl, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-Sipgate-Hubspot-UTK': getCookie('hubspotutk') || '',
			},
			body: JSON.stringify(signupData),
		});

		const jsonBody = await response.json();

		const createdResponse = jsonBody as SignupCreatedResponse;
		switch (response.status) {
			case 200:
				return ok(createdResponse);
			case 201:
				createdResponse.activationUrl = response.headers.get('Location') || undefined;
				return ok(createdResponse);
			case 409:
				return err(SignupApiErrors.EmailAlreadyExistsError(jsonBody.message));
			default:
				return err(SignupApiErrors.GeneralError());
		}
	}

	public async signup(data: SignupData): Promise<Result<SignupCreatedResponse, SignupApiError>> {
		return this.createSignup(data, undefined);
	}

	public async resendActivationEmail(
		identifier: string
	): Promise<Result<void, ActivationApiError>> {
		const response = await HttpClient.fetch(`${this.baseUrl}/v3/signup/resend`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ identifier }),
		});

		switch (response.status) {
			case 200:
			case 204:
				return ok(undefined);
			case 404:
				return err(ActivationApiErrors.NotFound(`Identifier ${identifier} not found`));
			default:
				return err(ActivationApiErrors.GeneralError());
		}
	}

	public async activate(code: string): Promise<Result<SignupCreatedResponse, ActivationApiError>> {
		const response = await HttpClient.fetch(`${this.baseUrl}/v3/signup/activate`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-Sipgate-Fingerprint': getFingerprint(),
			},
			body: JSON.stringify({ activationKey: code }),
		});

		const jsonBody = await response.json();

		if (response.status !== 200 && isActivationError(jsonBody)) {
			switch (jsonBody.apiErrorCode) {
				case ACTIVATION_NOT_FOUND:
					return err(ActivationApiErrors.NotFound(`Activation code ${code} not found`));
				case ACTIVATION_ALREADY_FINISHED:
					return err(
						ActivationApiErrors.AlreadyActivated(`Activation code ${code} already activated`)
					);
			}
		} else if (response.status !== 200) {
			return err(ActivationApiErrors.GeneralError());
		}
		return ok(jsonBody as SignupCreatedResponse);
	}

	public async getGbAddress(
		zip: string
	): Promise<Result<Array<UkAddressResponse>, AddressApiError>> {
		const url = new URL(`${this.baseUrl}/v3/address-lookup/gb`);
		url.searchParams.append('zip', zip);

		const response = await HttpClient.fetch(url.toString());
		if (response.status !== 200) {
			return err(AddressApiErrors.GeneralError());
		}

		const jsonBody: { items: UkAddressResponse[] } = await response.json();
		if (jsonBody.items.length === 0) {
			return err(AddressApiErrors.NoAddressFound('No address found'));
		}

		return ok(jsonBody.items);
	}

	public async getTacBySignedTacId(signedTacId: string): Promise<Result<TacIDResponse, ApiError>> {
		const url = new URL(`${this.baseUrl}/v3/signup/tacs/${signedTacId}`);
		const response = await HttpClient.fetch(url.toString());
		const jsonBody = await response.json();

		if (response.status !== 200) {
			return err(ApiErrors.GeneralApiError('Could not get Tac'));
		}
		return ok(jsonBody as TacIDResponse);
	}

	public async getTacIDs(
		domain: string,
		product: string
	): Promise<Result<Array<TacIDResponse>, ApiError>> {
		const url = new URL(`${this.baseUrl}/v3/signup/tacs/${domain}/${product.toUpperCase()}`);
		const response = await HttpClient.fetch(url.toString());
		const jsonBody = await response.json();

		if (response.status !== 200) {
			return err(ApiErrors.GeneralApiError('Could not get TacIDs'));
		}
		return ok(jsonBody.items as TacIDResponse[]);
	}

	public async getSignup(identifier: string): Promise<Result<SignupResponse, SignupApiError>> {
		const response = await HttpClient.fetch(`${this.baseUrl}/v3/signup/${identifier}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		});

		const jsonBody = await response.json();

		if (response.status !== 200 && isSignupApiError(jsonBody)) {
			switch (jsonBody.apiErrorCode) {
				case SIGNUP_NOT_FOUND_ERROR:
					return err(SignupApiErrors.NotFoundError(`Signup ${identifier} not found`));
				default:
					return err(SignupApiErrors.GeneralError());
			}
		} else if (response.status !== 200) {
			return err(SignupApiErrors.GeneralError());
		}

		return ok(jsonBody as SignupResponse);
	}

	public async savePartialSignup(
		identifier: string,
		data: SavePartialSignupRequest
	): Promise<Result<void, SignupApiError>> {
		const response = await HttpClient.fetch(`${this.baseUrl}/v3/signup/${identifier}`, {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});

		if (response.status !== 204) {
			return err(SignupApiErrors.GeneralError());
		}

		return ok(undefined);
	}
}
