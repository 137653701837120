import { Button, HeadingBoundary, Headline, ManagedInput } from '@panda/ui';
import { ManagedForm } from '@web-apps/forms';
import classnames from 'classnames';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import serviceUrls from '@web-apps/service-urls';
import { CountryField } from '../../components/fields/countryField/CountryField';
import GermanAddress from '../../components/groups/address/GermanAddress';
import InternationalAddress from '../../components/groups/address/InternationalAddress';
import UKAddress from '../../components/groups/address/UKAddress';
import { EmergencyCallsGroup } from '../../components/groups/emergencycalls/EmergencyCallsGroup';
import LoginGroup from '../../components/groups/login/LoginGroup';
import NQOnlyGroup from '../../components/groups/nqOnlyGroup/NQOnlyGroup';
import PersonalData from '../../components/groups/personalData/PersonalData';
import TacGroup from '../../components/groups/tac/TacGroup';
import { useLoadingAnimation } from '../../hooks/LoadingAnimation';
import useTranslations from '../../hooks/translations';
import { GermanSignupData, InternationalSignupData } from '../../types';
import { pushEvent } from '../../utils/tracking';
import Layout from './Layout';
import classes from './Signup.scss';
import {
	buildDeValidators as buildDeFormInformation,
	buildInternationalValidators as buildInternationalFormInformation,
	GermanFormValues,
	InternationalFormValues,
} from './Signup.validators';
import useSignupData from '../../hooks/SignupData';
import { useAddressData } from '../../hooks/AddressData';
import useTacs from '../../hooks/Tacs';
import { SipgateDomain } from '../../redux/slices/signup';

const getFormId = (country: string) => {
	switch (country) {
		case 'GB':
			return 'signupFormUK';
		case 'DE':
			return 'signupFormDE';
		default:
			return 'signupFormInternational';
	}
};

const getAddressComponent = (country: string) => {
	switch (country) {
		case 'GB':
			return <UKAddress />;
		case 'DE':
			return <GermanAddress />;
		default:
			return <InternationalAddress />;
	}
};

const getEmergencyCallsComponent = (domain: SipgateDomain) => {
	if (domain === 'sipgate.co.uk') {
		return <EmergencyCallsGroup />;
	}

	return null;
};

const FormReduxPersist = (props: { hooks: (() => void)[] }) => {
	const formik = useFormikContext<GermanFormValues | InternationalFormValues>();

	const { clearAddressData: clearSignupAddressData } = useAddressData();

	const { signupData, clearAddressData } = useSignupData();
	const { country } = signupData;

	const { values } = formik;
	const { hooks } = props;

	useEffect(() => {
		if (country !== values.country) {
			if (formik.getFieldMeta('country').touched) {
				clearAddressData(values.country);
				clearSignupAddressData(values.country);
			}
		}
	}, [clearSignupAddressData, clearAddressData, values.country, country, formik]);

	hooks.forEach(hook => hook());

	return null;
};

const Signup = () => {
	const [shouldValidate, setShouldValidate] = useState(false);
	const { signupData, createSignup } = useSignupData();
	const {
		activationUrl,
		activationCode,
		createdPending,
		country,
		domain,
		identityProvider: idp,
		product,
		error: signupError,
		identifier,
		fetched: isSignupFetched,
		status: signupStatus,
	} = signupData;

	const [canBeReinitialized, setCanBeReinitialized] = useState(true);

	const { translate, fetched: translationsFetched } = useTranslations();
	const { hasError: hasAddressError } = useAddressData();

	const history = useHistory();
	const loadingAnimation = useLoadingAnimation();

	useEffect(() => {
		if (isSignupFetched) {
			switch (signupStatus) {
				case 'FAILED':
					history.replace(`/error`);
					break;
				case 'SUCCEEDED':
					window.location.href = serviceUrls.appWeb;
					break;
				case 'PENDING':
					history.replace(`/success/${identifier}`);
					break;
				default:
					break;
			}
		}
	}, [isSignupFetched, signupStatus, history, identifier]);

	useEffect(() => {
		if (signupError) {
			if (signupError.name === 'COULD_NOT_FIND_SIGNUP') {
				history.replace('/');
				loadingAnimation.hide();
			}
		}
	}, [signupError, loadingAnimation, history]);

	useEffect(() => {
		if (translationsFetched && isSignupFetched) {
			loadingAnimation.hide();
			setCanBeReinitialized(false);
		}
	}, [isSignupFetched, translationsFetched, loadingAnimation]);

	useEffect(() => {
		if (createdPending && identifier) {
			if (activationUrl) {
				window.location.href = activationUrl;
			} else {
				history.replace(`/success/${identifier}`, {
					activationCode,
				});
			}
		}
	}, [createdPending, identifier, history, activationCode, activationUrl]);

	const formId = getFormId(country);
	const filterFor = domain === 'sipgate.de' ? 'GB' : 'DE';

	const { tacs } = useTacs();

	if (country === 'DE') {
		const { validate, validators, initialValues, hooks } = buildDeFormInformation(
			country,
			domain,
			idp || 'SIPGATE',
			false,
			translate,
			signupData
		);

		const onSubmit = async (values: GermanFormValues, formik: FormikHelpers<GermanFormValues>) => {
			const data = ManagedForm.collectData(validators, () => [], values);
			if (data.state !== 'valid') {
				formik.setErrors(data.fieldErrors);
				return;
			}

			const partialSignupData: Omit<GermanSignupData, 'domain' | 'product' | 'identityProvider'> = {
				firstname: data.values.firstname,
				lastname: data.values.lastname,
				phoneNumber: data.values.phoneNumber.number.replace('+', ''),
				company: data.values.company,
				country,
				zip: data.values.zip,
				city: data.values.city,
				street: data.values.street,
				number: data.values.number,
				email: data.values.email,
				password: data.values.password,
				testaccount: data.values.testaccount,
				tacIds: tacs.map(tac => tac.signedTacIdentifier),
			};

			await createSignup(partialSignupData);

			pushEvent('success', formId, product);
		};

		return (
			<Layout>
				<div className={classes.content}>
					<Formik
						initialValues={initialValues}
						onSubmit={onSubmit}
						validate={validate}
						validateOnBlur={false}
						validateOnChange={false}
						isInitialValid={false}
						enableReinitialize={canBeReinitialized}
					>
						{formik => {
							return (
								<Form
									id={formId}
									onSubmit={async e => {
										e.preventDefault();
										if (!shouldValidate) {
											setShouldValidate(true);
											await formik.validateForm();
										}
										const data = ManagedForm.collectData(validators, () => [], formik.values);
										if (Object.keys(data.fieldErrors).length > 0) {
											pushEvent('failure', formId, product, data.fieldErrors);
										}
										formik.handleSubmit(e);
									}}
								>
									<>
										<FormReduxPersist hooks={hooks} />
										<div className={classes.formContent}>
											<LoginGroup />
											<PersonalData />
											<div
												id="AddressGroup"
												className={classes.group}
												role="region"
												aria-labelledby="AddressGroupHeadline"
											>
												<HeadingBoundary>
													<Headline id="AddressGroupHeadline">
														{translate('SIGNUP_ADDRESS_HEADLINE')}
													</Headline>
													<div
														className={classnames(classes.row, classes.width6, classes.mobilefw)}
													>
														<ManagedInput
															managedField={ManagedForm.buildField(formik, 'company')}
															label={translate('SIGNUP_ADDRESS_COMPANY_LABEL')}
															placeholder=""
														/>
													</div>
													<div
														className={classnames(classes.row, classes.width6, classes.mobilefw)}
													>
														<CountryField filter={item => item !== filterFor} />
													</div>
													{getAddressComponent(country)}
												</HeadingBoundary>
											</div>
											<NQOnlyGroup />
											{getEmergencyCallsComponent(domain)}
											<span className={classes.tacs}>
												<TacGroup />
											</span>
											<div className={classes.submit}>
												<Button
													type="submit"
													action="confirm"
													size="xlarge"
													variant="loud"
													width="max-on-touch-device"
													loading={formik.isSubmitting}
												>
													{translate('SIGNUP_SUBMIT_BUTTON')}
												</Button>
											</div>
										</div>
									</>
								</Form>
							);
						}}
					</Formik>
				</div>
			</Layout>
		);
	}

	const { validate, validators, initialValues, hooks } = buildInternationalFormInformation(
		country,
		domain,
		idp || 'SIPGATE',
		false,
		translate,
		signupData,
		hasAddressError
	);

	const onSubmit = async (
		values: InternationalFormValues,
		formik: FormikHelpers<InternationalFormValues>
	) => {
		const data = ManagedForm.collectData(validators, () => [], values);
		if (data.state !== 'valid') {
			formik.setErrors(data.fieldErrors);
			return;
		}

		const partialSignupData: Omit<
			InternationalSignupData,
			'domain' | 'product' | 'identityProvider'
		> = {
			firstname: data.values.firstname,
			lastname: data.values.lastname,
			phoneNumber: data.values.phoneNumber.number.replace('+', ''),
			company: data.values.company,
			country: data.values.country,
			zip: data.values.zip,
			city: data.values.city,
			internationalAddress1: data.values.address1,
			internationalAddress2: data.values.address2,
			email: data.values.email,
			password: data.values.password,
			testaccount: data.values.testaccount,
			tacIds: tacs.map(tac => tac.signedTacIdentifier),
		};

		await createSignup(partialSignupData);

		pushEvent('success', formId, product);
	};

	return (
		<Layout>
			<div className={classes.content}>
				<Formik
					initialValues={initialValues}
					onSubmit={onSubmit}
					validate={validate}
					validateOnBlur={false}
					validateOnChange={false}
					isInitialValid={false}
					enableReinitialize={canBeReinitialized}
				>
					{formik => {
						return (
							<Form
								id={formId}
								onSubmit={async e => {
									e.preventDefault();
									if (!shouldValidate) {
										setShouldValidate(true);
										await formik.validateForm();
									}
									const data = ManagedForm.collectData(validators, () => [], formik.values);
									if (Object.keys(data.fieldErrors).length > 0) {
										pushEvent('failure', formId, product, data.fieldErrors);
									}
									formik.handleSubmit(e);
								}}
							>
								<>
									<FormReduxPersist hooks={hooks} />
									<div className={classes.formContent}>
										<LoginGroup />
										<PersonalData />
										<div
											id="AddressGroup"
											className={classes.group}
											role="region"
											aria-labelledby="AddressGroupHeadline"
										>
											<HeadingBoundary>
												<Headline id="AddressGroupHeadline">
													{translate('SIGNUP_ADDRESS_HEADLINE')}
												</Headline>
												<div className={classnames(classes.row, classes.width6, classes.mobilefw)}>
													<ManagedInput
														managedField={ManagedForm.buildField(formik, 'company')}
														label={translate('SIGNUP_ADDRESS_COMPANY_LABEL')}
														placeholder=""
													/>
												</div>
												<div className={classnames(classes.row, classes.width6, classes.mobilefw)}>
													<CountryField filter={item => item !== filterFor} />
												</div>
												{getAddressComponent(country)}
											</HeadingBoundary>
										</div>
										<NQOnlyGroup />
										{getEmergencyCallsComponent(domain)}
										<span className={classes.tacs}>
											<TacGroup />
										</span>
										<div className={classes.submit}>
											<Button
												type="submit"
												action="confirm"
												size="xlarge"
												variant="loud"
												width="max-on-touch-device"
												loading={formik.isSubmitting}
											>
												{translate('SIGNUP_SUBMIT_BUTTON')}
											</Button>
										</div>
									</div>
								</>
							</Form>
						);
					}}
				</Formik>
			</div>
		</Layout>
	);
};

export { Signup };
