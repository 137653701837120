import { ErrorBase } from '../../types';
import { IdentityProvider } from '../../../types';

export type SipgateProduct = 'team' | 'trunking' | 'affiliate' | 'team_neopbx';
export type SipgateDomain = 'sipgate.de' | 'sipgate.co.uk';
export type SipgateSignupStatus = 'STARTED' | 'FAILED' | 'SUCCEEDED' | 'PARTIAL' | 'PENDING';
export type SignupState = {
	email?: string;
	country: string;
	domain: SipgateDomain;
	product: SipgateProduct;
	tacIds: number[];
	creatingPending: boolean;
	createdPending: boolean;
	creatingPartial: boolean;
	createdPartial: boolean;
	activating: boolean;
	activated: boolean;
	error?: SignupErrors;
	resendError?: SignupActivationEmailResendErrors;
	identifier?: string;
	activationCode?: string;
	resending: boolean;
	resented: boolean;
	fetching: boolean;
	fetched: boolean;
	firstname?: string;
	lastname?: string;
	company?: string;
	phoneNumber?: string;
	status?: SipgateSignupStatus;
	subscribedToNewsletter?: boolean;
	zip?: string;
	city?: string;
	street?: string;
	number?: string;
	address1?: string;
	address2?: string;
	identityProvider?: IdentityProvider;
	avatarUrl?: string;
	activationUrl?: string;
};

export type RootSignupState = {
	signup: SignupState;
};

export type SignupErrors = ErrorBase<
	| 'COULD_NOT_CREATE_PARTIAL_SIGNUP'
	| 'COULD_NOT_CREATE_PENDING_SUBSCRIBER'
	| 'COULD_NOT_ACTIVATE_PENDING_SUBSCRIBER'
	| 'ALREADY_ACTIVATED_ERROR'
	| 'ACTIVATION_NOT_FOUND_ERROR'
	| 'EMAIL_ALREADY_EXISTS'
	| 'COULD_NOT_FIND_SIGNUP'
	| 'GENERAL_SIGNUP_ERROR'
>;

export type SignupActivationEmailResendErrors = ErrorBase<
	'COULD_NOT_RESEND_ACTIVATION_EMAIL' | 'SIGNUP_ACTIVATION_EMAIL_RECIPIENT_NOT_FOUND'
>;

export const CouldNotCreatePartialSignup = (message: string): SignupErrors => ({
	name: 'COULD_NOT_CREATE_PARTIAL_SIGNUP',
	message,
});
export const CouldNotCreatePendingSubscriber = (message: string): SignupErrors => ({
	name: 'COULD_NOT_CREATE_PENDING_SUBSCRIBER',
	message,
});

export const EmailAlreadyExists = (message: string): SignupErrors => ({
	name: 'EMAIL_ALREADY_EXISTS',
	message,
});

export const CouldNotActivatePendingSubscriber = (message: string): SignupErrors => ({
	name: 'COULD_NOT_ACTIVATE_PENDING_SUBSCRIBER',
	message,
});

export const AlreadyActivatedError = (message: string): SignupErrors => ({
	name: 'ALREADY_ACTIVATED_ERROR',
	message,
});

export const ActivationNotFoundError = (message: string): SignupErrors => ({
	name: 'ACTIVATION_NOT_FOUND_ERROR',
	message,
});

export const RecipientNotFoundError = (message: string): SignupActivationEmailResendErrors => ({
	name: 'SIGNUP_ACTIVATION_EMAIL_RECIPIENT_NOT_FOUND',
	message,
});

export const GenericResendActivationEmailError = (
	message: string
): SignupActivationEmailResendErrors => ({
	name: 'COULD_NOT_RESEND_ACTIVATION_EMAIL',
	message,
});

export const CouldNotFindSignup = (message: string): SignupErrors => ({
	name: 'COULD_NOT_FIND_SIGNUP',
	message,
});

export const GeneralSignupError = (): SignupErrors => ({
	name: 'GENERAL_SIGNUP_ERROR',
	message: 'General signup error',
});
