import React from 'react';
import { useHistory } from 'react-router';
import { Button, Headline } from '@panda/ui';
import { useAppDispatch } from '../../hooks/redux';

import classes from './Success.scss';
import Layout from '../signup/Layout';
import NQActivate from '../../components/nqNupsi/NQActivate';
import { resendActivation } from '../../redux/slices/signup';
import { useTranslations } from '../../hooks/translations';
import useSignupData from '../../hooks/SignupData';
import { MarkdownTranslate } from '../../utils/markdown';

const Success = () => {
	const dispatch = useAppDispatch();
	const { translate } = useTranslations();
	const history = useHistory();

	const { signupData } = useSignupData();
	const { email, identifier, resendError, resending, resented } = signupData;

	if (!email) {
		history.replace('/');
		return <></>;
	}

	return (
		<Layout>
			<div className={classes.success}>
				<div className={classes.content}>
					<Headline>
						<MarkdownTranslate
							translate={translate}
							translationKey="SIGNUP_SUCCESS_HEADLINE"
							placeholder={[]}
						/>
					</Headline>
					<span className={classes.message}>
						<MarkdownTranslate
							translate={translate}
							translationKey="SIGNUP_SUCCESS_MESSAGE"
							placeholder={[email]}
						/>
					</span>
					{identifier && (
						<div className={classes.resend}>
							<span>{translate('SIGNUP_SUCCESS_RESEND_EMAIL_HINT')}</span>
							<div className={classes.wrapper}>
								<Button
									onClick={() => {
										dispatch(resendActivation(identifier));
									}}
									size="small"
									variant="normal"
									disabled={resending || resented}
								>
									{translate('SIGNUP_RESEND_ACTIVATION_EMAIL_BUTTON')}
								</Button>
							</div>
							{resendError && (
								<span className={classes.error}>
									<MarkdownTranslate
										translate={translate}
										placeholder={[]}
										translationKey="SIGNUP_RESEND_ACTIVATION_EMAIL_ERROR"
									/>
								</span>
							)}
							{resented && (
								<span className={classes.success}>
									<MarkdownTranslate
										translate={translate}
										placeholder={[]}
										translationKey="SIGNUP_RESEND_ACTIVATION_EMAIL_SUCCESS"
									/>
								</span>
							)}
						</div>
					)}
					<NQActivate />
				</div>
			</div>
		</Layout>
	);
};

export { Success };
